export function createSingularEvent(eventName, openDownloadURl) {
  if (window.singularSdk) {
    window.singularSdk.event(eventName)
  }
}

export function handleDownloadApp(downloadUrl) {
  if (window.singularSdk) {
    window.singularSdk?.openApp(downloadUrl)
  }
}
