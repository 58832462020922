import React from "react"
import DynamicRating from "./DynamicStar"
import star from "../images/star.svg"
import "../styles/reviews.css"
import Disclaimer from "./Disclaimer"
import ConfigContext from "./utils/configContext"

export default class Reviews extends React.Component {
  static contextType = ConfigContext

  render() {
    let { filterReviews } = this.props

    const mostMentionedText =
      this.context.mostMentionedText || "Most mentioned in reviews:"

    const ratingsHeading = this.context.ratingsHeading || "Ratings and reviews"

    const gameplayText = this.context.gameplayText || "Gameplay"
    const controlsText = this.context.controlsText || "Controls"
    const graphicsText = this.context.graphicsText || "Graphics"
    return (
      <div
        className="container"
        style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
      >
        <p className="weight500">{ratingsHeading}</p>
        <div className="maxw">
          <div className="w30">
            <p className=" font3">{this.context.ratingValue || "4.8"}</p>
            <div className="reviewsCount-flex">
              <DynamicRating
                value={`${((this.context.ratingValue || 4.8) / 5) * 100}`}
              />
              <p className="reviews-count">
                <span>11,378</span>
              </p>
            </div>
          </div>
          <div className="w70">
            <a href={"#reviews"}>
              <div
                className="flexBar"
                onClick={() => filterReviews("5")}
                onKeyDown={() => filterReviews("5")}
                role="button"
                tabIndex="0"
              >
                <span className="is-size-7">5</span> &nbsp;&nbsp;
                <div className="progress">
                  <div className="progressWidth5 progressWidth" />
                </div>
              </div>
            </a>
            <a href={"#rev4"}>
              <div
                className="flexBar"
                onClick={() => filterReviews("4")}
                onKeyDown={() => filterReviews("4")}
                role="button"
                tabIndex="0"
              >
                <span className="is-size-7">4</span> &nbsp;&nbsp;
                <div className="progress">
                  <div className="progressWidth4 progressWidth" />
                </div>
              </div>
            </a>
            <a href={"#rev3"}>
              <div
                className="flexBar"
                onClick={() => filterReviews("3")}
                onKeyDown={() => filterReviews("3")}
                role="button"
                tabIndex="0"
              >
                <span className="is-size-7">3</span> &nbsp;&nbsp;
                <div className="progress">
                  <div className="progressWidth3 progressWidth" />
                </div>
              </div>
            </a>
            <a href={"#rev2"}>
              <div
                className="flexBar"
                onClick={() => filterReviews("2")}
                onKeyDown={() => filterReviews("2")}
                role="button"
                tabIndex="0"
              >
                <span className="is-size-7">2</span> &nbsp;&nbsp;
                <div className="progress">
                  <div className="progressWidth2 progressWidth" />
                </div>
              </div>
            </a>
            <a href={"#rev1"}>
              <div
                className="flexBar"
                onClick={() => filterReviews("1")}
                onKeyDown={() => filterReviews("1")}
                role="button"
                tabIndex="0"
              >
                <span className="is-size-7">1</span> &nbsp;&nbsp;
                <div className="progress">
                  <div className="progressWidth1 progressWidth" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="additional-stats">
          <div className="gameplay">
            <p className="is-size-7 mar-8">{gameplayText}</p>
            <p>
              <span className="has-text-black size-14">4.8</span>
              <span style={{ marginLeft: "2px" }}>
                <img className="ratingStar-small" src={star} alt={""} />
              </span>
            </p>
          </div>
          <div className="controls">
            <p className="is-size-7 mar-8">{controlsText}</p>
            <p>
              <span className="has-text-black size-14">4.9</span>
              <span style={{ marginLeft: "2px" }}>
                <img className="ratingStar-small" src={star} alt={""} />
              </span>
            </p>
          </div>
          <div className="graphics">
            <p className="is-size-7 mar-8">{graphicsText}</p>
            <p>
              <span className="has-text-black size-14">4.8</span>
              <span style={{ marginLeft: "2px" }}>
                <img className="ratingStar-small" src={star} alt={""} />
              </span>
            </p>
          </div>
        </div>
        <hr />
        <div className="most-mentioned ">
          <div className="mentioned-text">{mostMentionedText}</div>
          <div className="most-mentioned-tags">
            {this.context.reviewTags?.map((tag, i) => (
              <span className="tag is-rounded" key={i}>
                {tag}
              </span>
            ))}
          </div>
        </div>
        <Disclaimer />
      </div>
    )
  }
}
