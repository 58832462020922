const isMobile = () =>
  typeof window !== "undefined" &&
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const isIOS = () =>
  typeof window !== "undefined" && /iPhone|iPad|iPod/i.test(navigator.userAgent)

const isAndroid = () =>
  typeof window !== "undefined" && /Android/i.test(navigator.userAgent)

const getDeviceLink = ({ web, ios, android }) => {
  if (isMobile()) {
    if (isIOS()) {
      return ios
    } else if (isAndroid()) {
      return android
    }
  }
  return web
}

module.exports = { getDeviceLink }
