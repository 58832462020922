import React from "react"
import withLocation from "./withLocation"
import { StaticImage } from "gatsby-plugin-image"
import LogoOld from "../images/logo.svg"
import Stats from "./Stats"
import ConfigContext from "./utils/configContext"
import {
  createSingularEvent,
  handleDownloadApp,
} from "./utils/trackSingularEvent"
import { SINGULAR_EVENTS } from "./utils/constant.utils"

class Header extends React.Component {
  static contextType = ConfigContext

  state = {
    isOpen: false,
    staticTags: this.context.staticTags || [],
    installUrl: "",
    loaded: false,
    oneLink: "",
  }

  singularEventClick() {
    createSingularEvent(SINGULAR_EVENTS.DOWNLOAD_BUTTON_CLICKED)
    handleDownloadApp(this.props.installUrl)
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loaded: true,
    })
  }

  nonChromeFunc = e => {
    this.props.toggleModal()
    !this.context.singularProductId &&
      setTimeout(() => {
        window.location = this.state.oneLink || this.props.installUrl
      }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome
      ? this.props.togglePrompt()
      : this.nonChromeFunc(eventSource)
    this.singularEventClick()
  }

  render() {
    const showDisclaimer = this?.context?.showDisclaimer
    let { tag1, tag2, tag3 } = this.props.search
    let tags = []
    tag1 && tags.push(tag1)
    tag2 && tags.push(tag2)
    tag3 && tags.push(tag3)

    let tagsArr = tags.length !== 0 ? tags : this.state.staticTags
    let { convertedTags, loaded } = this.state

    const LogoRummy = (
      <StaticImage
        src="../images/megarummy-icon.jpg"
        alt="mega rummy"
        width={90}
        height={90}
      />
    )

    const LogoPoker = (
      <StaticImage
        src="../images/megapoker-icon.png"
        alt="mega poker"
        width={90}
        height={90}
      />
    )

    const logoImage =
      this.context.gameCategory === "rummy" ? LogoRummy : LogoPoker

    const titleSection = (
      <>
        <p
          className="is-size-7 weight-500 developer"
          style={{ cursor: "pointer" }}
        >
          {this.context.gamesType ? this.context.gamesType : "MegaGames"}
        </p>
        <div className="developer">
          {loaded &&
            (this.context.cms && convertedTags ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: convertedTags,
                }}
              />
            ) : (
              <>
                {tagsArr.map(tag => (
                  <span key={tag} className="tag is-rounded">
                    {tag}
                  </span>
                ))}
              </>
            ))}
        </div>
      </>
    )

    return (
      <div className="header container">
        <div className="imageSection">
          <div
            className={`logo install_button ${this.context.gameCategory}`}
            data-onelink=""
            onClick={e => {
              this.setState({ oneLink: e.target.getAttribute("data-onelink") })
              this.onClickHandler("Logo Button")
            }}
            onKeyDown={e => {
              this.setState({ oneLink: e.target.getAttribute("data-onelink") })
              this.onClickHandler("Logo Button")
            }}
            role="button"
            tabIndex="0"
          >
            {this.context.oldLogo ? (
              <img src={LogoOld} alt="mega" width="110" height="27" />
            ) : (
              logoImage
            )}
          </div>

          <div
            className="app-name install_button"
            data-onelink=""
            onClick={e => {
              this.setState({ oneLink: e.target.getAttribute("data-onelink") })
              this.onClickHandler("Title Button")
            }}
            onKeyDown={e => {
              this.setState({ oneLink: e.target.getAttribute("data-onelink") })
              this.onClickHandler("Title Button")
            }}
            role="button"
            tabIndex="0"
          >
            {titleSection}
          </div>
        </div>

        <div className="stats-container">
          <Stats
            fbEventAndShowModal={this.props.fbEventAndShowModal}
            togglePrompt={this.props.togglePrompt}
            toggleModal={this.props.toggleModal}
            isChrome={this.props.isChrome}
            installUrl={this.props.installUrl}
          />
        </div>

        <div className="install-button">
          <button
            type="button"
            size="is-small"
            className="weight-700 install_button"
            data-onelink=""
            onClick={e => {
              this.setState({ oneLink: e.target.getAttribute("data-onelink") })
              this.onClickHandler("Primary Install Button")
            }}
          >
            {this.context.installText || "Download App"}
          </button>
          {this.context.hotstarPage && (
            <p className="download-bottom-text">
              Allow Outside Google Playstore App Download
            </p>
          )}
        </div>
        <div>
          {showDisclaimer && (
            <p className="disclaimer-paytm">
              Disclaimer : Outside Google Playstore App
            </p>
          )}
        </div>
        <div className="button-bottom-text">
          🔥Limited time offer - Play unlimited free cashgames
        </div>
      </div>
    )
  }
}

export default withLocation(Header)
