import React from "react"
import ConfigContext from "./utils/configContext"

class Faqs extends React.Component {
  static contextType = ConfigContext

  render() {
    const rummyFaqs = [
      {
        question: "Is rummy legal to play in India?",
        answer: `<p>
        Yes, the Supreme Court of India has declared rummy a skill game
        and ruled that playing a skill game like rummy for real money is
        a business activity, not betting or gambling. Therefore, it is
        absolutely legal to play online rummy for both cash and for free
        in India.
      </p>`,
      },
      {
        question: "What rummy game variants are available on Mega Rummy?",
        answer: `<p>
        Mega Rummy has all the variants of rummy like Points Rummy,
        Pool Rummy (51, 101 and 201 Pool) and Deals Rummy (Best of 2,
        Best of 3).
      </p>`,
      },
      {
        question: "How do you sign up for Mega Rummy?",
        answer: `<p>
        1. Download the Mega Rummy app onto your mobile phone.
        <br />
        2. Enter your mobile number and click on "Register for Free".
        That's it!
      </p>`,
      },
      {
        question: "Is the Mega Rummy app free or paid?",
        answer: `<p>
        The Mega Rummy app is free to download and play on mobile.
        You can play rummy online for free by engaging in unlimited
        practice games on the app. You can also play for cash by paying
        a small entry fee, as low as Rs.1, in cash games or tournaments.
      </p>`,
      },
      {
        question: "How to withdraw cash from the app?",
        answer: `<p>
        You can easily withdraw the winning amount by placing a request
        for cash withdrawal and then enter the amount you wish to
        withdraw and the amount will be credited to your bank account,
        instantly.
      </p>`,
      },
    ]
    const pokerFaqs = [
      {
        question: "What is the best online poker game for real money?",
        answer: `<p>No poker game shall rank higher in terms of making money. It all depends on the player's choice and expertise in the game they choose to play. Texas Hold’em and 4 Card PLO are the top picks if you wish to make decent money. These poker variations are easily accessible on credible online poker platforms of the day. Not to mention, tournaments on Mega Poker are an excellent choice if you want to win bigger cash rewards.</p>`,
      },
      {
        question: "How to play online poker for free on Mega Poker?",
        answer: `<p>Mega Poker has got just the perfect selection of games to get you started. Simply download the Mega Poker apk file on your Android smartphone from the website.</p>
        <p>Next, install the Mega Poker app and sign into your account. Select the game you wish to play and get gaming right away.</p>`,
      },
      {
        question: "Is Poker Legal in India?",
        answer: `<p>Yes, Poker is legal to play in India. However, the misconceptions regarding the legality of the game can be misleading. The Supreme Court in its various judgements has stated games of skill as a fundamental right under the Indian Constitution. Therefore, Poker is permitted by the Indian government as a legal activity since it demands a substantial degree of skill to play well.</p>
        <p>However, the states reserve their constitutional right to make their laws. Therefore, aside from the states of Assam, Telangana, Nagaland, Sikkim, Odisha and Andhra Pradesh, citizens of India can play cash games or tournaments of live and online poker.</p>`,
      },
      {
        question: "Is poker a mental game?",
        answer: `<p>Yes, Poker is a mind sport that relies heavily on the mind. You need to carry a specific set of mental skills- emotional maturity, strategic thinking, undivided focus on the table, acute observational skills to deceive your opponents and basic mental maths to make informed decisions.</p>`,
      },
      {
        question: "Can You Win Real Money in an Online Poker Game?",
        answer: `<p>Yes, you can win a substantial amount of <strong>real money</strong> if you learn and study the subtleties of the Poker game. Exercising your mental skills and picking the right online poker games on a licensed and player-oriented platform can give you opportunities to win real money.</p>
        <p>Try the Mega Poker app if you haven’t already and enjoy a variety of options, from cash tables of the lowest to the highest stakes or simply pick your favourite tournaments, and make it to the top to earn huge cash rewards.</p>`,
      },
    ]

    const faqData =
      this.context.gameCategory === "rummy" ? rummyFaqs : pokerFaqs
    return (
      <>
        <div className="faqs-wrap container">
          <h4>FAQs</h4>
          {faqData.map((item, i) => (
            <div className="faqs-item" key={i}>
              <h5>{item.question}</h5>
              <div
                className="faqs-content"
                dangerouslySetInnerHTML={{
                  __html: item.answer,
                }}
              />
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default Faqs
